import * as React from "react";
import { useLocation } from "@reach/router";
import { clsx } from "clsx";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import SearchResults from "../components/SearchResults/SearchResults";
import SearchResult from "../components/SearchResult/SearchResult";
import Button from "../components/Button/Button";
import SearchResultCarousel from "../components/SearchResultCarousel/SearchResultCarousel";
import FeaturedIn from "../components/FeaturedIn/FeaturedIn";
import Footer from "../components/Footer/Footer";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";

import "./ActivityPage.css";
import {
  getCategoriesByCity as apiGetCategoriesByCity,
  getActivitiesByCity as apiGetActivitiesByCity,
  getActivitiesByCountry as apiGetActivitiesByCountry,
} from "../services/api";

import { GlobalStateContext } from "../context/GlobalContextProvider";

const createSlug = (title) => {
  const slug = title.replace(/\W+/g, "-").toLowerCase();
  return slug;
};

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const CityPage = ({ location, pageContext }) => {
  const isFirstRender = React.useRef(true);

  const context = React.useContext(GlobalStateContext);

  const params = new URLSearchParams(location.search);
  const category = params.get("category");

  const [expanded, setExpanded] = React.useState(false);

  const [cityActivities, setCityActivities] = React.useState([]);
  const [loadingCityActivities, setLoadingCityActivities] =
    React.useState(false);
  const [countryActivities, setCountryActivities] = React.useState([]);
  const [loadingCountryActivities, setLoadingCountryActivities] =
    React.useState(false);
  const [foodTourExperienceActivities, setFoodTourExperienceActivities] =
    React.useState([]);
  const [
    loadingFoodTourExperienceActivities,
    setLoadingFoodTourExperienceActivities,
  ] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [loadingCategories, setLoadingCategories] = React.useState(false);

  const [selectedRootCategory, setSelectedRootCategory] = React.useState(null);
  const [filterCategories, setFilterCategories] = React.useState(
    category ? [category] : []
  );
  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(450);

  const { city, rootCategories } = pageContext;

  let reloadTimerId = null;

  const getCategoriesByCity = async () => {
    // setErrors([]);

    if (!context.currentCurrency) {
      return null;
    }

    try {
      setLoadingCategories(true);

      const data = await apiGetCategoriesByCity(
        city.id,
        context.currentCurrency,
        minPrice,
        maxPrice,
        searchTerm
      );

      if (data) {
        setCategories(data);
      }
      setLoadingCategories(false);
    } catch (e) {
      console.log(e);
      setLoadingCategories(false);
    }
  };

  const getCityFoodTourExperienceActivities = async () => {
    // setErrors([]);

    if (!context.currentCurrency) {
      return null;
    }

    try {
      setLoadingFoodTourExperienceActivities(true);

      const data = await apiGetActivitiesByCity(
        city.id,
        context.currentCurrency,
        "Food Tours",
        0,
        450
      );

      if (data) {
        setFoodTourExperienceActivities(data);
      }
      setLoadingFoodTourExperienceActivities(false);
    } catch (e) {
      console.log(e);
      setLoadingFoodTourExperienceActivities(false);
    }
  };

  const getActivitiesByCity = async () => {
    // setErrors([]);

    if (!context.currentCurrency) {
      return null;
    }

    try {
      setLoadingCityActivities(true);

      const filterCategoriesStr = filterCategories
        .map((cat) => encodeURIComponent(cat))
        .join(",");

      const data = await apiGetActivitiesByCity(
        city.id,
        context.currentCurrency,
        filterCategoriesStr,
        minPrice,
        maxPrice,
        searchTerm
      );

      if (data) {
        setCityActivities(data);
      }
      setLoadingCityActivities(false);
    } catch (e) {
      console.log(e);
      setLoadingCityActivities(false);
    }
  };

  const getActivitiesByCountry = async () => {
    // setErrors([]);

    if (!context.currentCurrency) {
      return null;
    }

    try {
      setLoadingCountryActivities(true);

      const data = await apiGetActivitiesByCountry(
        city.countryId,
        context.currentCurrency,
        "",
        0,
        450,
        ""
      );

      if (data) {
        setCountryActivities(data);
      }
      setLoadingCountryActivities(false);
    } catch (e) {
      console.log(e);
      setLoadingCountryActivities(false);
    }
  };

  const debouncedGetCategoriesByCountry = debounce(
    getActivitiesByCountry,
    3000
  );

  React.useEffect(() => {
    getActivitiesByCity();
  }, [context.currentCurrency, filterCategories]);

  React.useEffect(() => {
    if (isFirstRender.current) {
      // Set isFirstRender to false after the first render
      isFirstRender.current = false;
      return;
    }

    const handleTimeout = () => {
      getCategoriesByCity();
      getActivitiesByCity();
      reloadTimerId = null;
    };

    if (reloadTimerId) {
      clearTimeout(reloadTimerId);
    }

    reloadTimerId = setTimeout(handleTimeout, 750);

    return () => {
      clearTimeout(reloadTimerId);
    };
  }, [searchTerm, minPrice, maxPrice]);

  React.useEffect(() => {
    getCategoriesByCity();
  }, [context.currentCurrency]);

  React.useEffect(() => {
    debouncedGetCategoriesByCountry();
    // getCityFoodTourExperienceActivities();
  }, [context.currentCurrency]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [loadingCategories, loadingCityActivities]);

  const countryPath = `/${createSlug(city.country.name)}`;

  let regionPath = "";

  if (city.regionCities && city.regionCities.length > 0) {
    regionPath = `/${createSlug(
      city.country.name
    )}/${city.regionCities[0].region.slug.replace(
      `-${createSlug(city.country.name)}`,
      ""
    )}`;
  }

  let cityPath = `/${createSlug(city.country.name)}/${city.slug.replace(
    `-${createSlug(city.country.name)}`,
    ""
  )}`;

  if (city.regionCities && city.regionCities.length > 0) {
    cityPath = `/${createSlug(
      city.country.name
    )}/${city.regionCities[0].region.slug.replace(
      `-${createSlug(city.country.name)}`,
      ""
    )}/${city.slug.replace(`-${createSlug(city.country.name)}`, "")}`;
  }

  return (
    <Page>
      <TopBar showSearch={true} />
      {city.name && (
        <Section
          style={{
            color: "#fff",
            lineHeight: "30px",
          }}
        >
          {
            <Breadcrumbs>
              <a href={countryPath}>{city.country.name}</a> /
              {city.regionCities && city.regionCities.length > 0 && (
                <>
                  <a href={regionPath}>{city.regionCities[0].region.name}</a> /{" "}
                </>
              )}
              <a href={cityPath}>
                {city.name.replace(`, ${city.country.name}`, "")}
              </a>
            </Breadcrumbs>
          }
          <SectionHeading>{city.name}</SectionHeading>
          {city.description && (
            <div>
              <div
                className={clsx("activity-description", {
                  expanded,
                })}
              >
                {city.description}
              </div>
              <Button secondary compact onClick={() => setExpanded(!expanded)}>
                {expanded ? "Read less" : "Read more"}
              </Button>
            </div>
          )}
        </Section>
      )}
      {/* {foodTourExperienceActivities &&
        foodTourExperienceActivities.length > 0 && (
          <Section backgroundColor="#f5f5f5">
            <SectionHeading component="h2">
              {city.name.toUpperCase()} FOOD TOURS & EXPERIENCES
            </SectionHeading>
            <SearchResultCarousel
              slides={foodTourExperienceActivities.map((activity) => (
                <SearchResult compact={true} searchResult={activity} />
              ))}
            />
          </Section>
        )} */}
      <Section backgroundColor="#F1F7FA">
        {cityActivities && (
          <SearchResults
            categories={categories}
            selectedRootCategory={selectedRootCategory}
            setSelectedRootCategory={setSelectedRootCategory}
            filterCategories={filterCategories}
            setFilterCategories={setFilterCategories}
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
            searchResults={cityActivities}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            loading={loadingCategories || loadingCityActivities}
          />
        )}
      </Section>
      <Section backgroundColor="#fff">
        <SectionHeading component="h2">
          SEE MORE THINGS TO DO IN {city.country.name.toUpperCase()}
        </SectionHeading>
        <SearchResultCarousel
          slides={
            countryActivities &&
            countryActivities.map((activity) => (
              <SearchResult compact={true} searchResult={activity} />
            ))
          }
        />
      </Section>
      {/* <Section>
        <SectionHeading component="h2">LATEST OFFERS</SectionHeading>
      </Section> */}
      <FeaturedIn />
      <Footer />
    </Page>
  );
};

export default CityPage;

export const Head = ({ pageContext }) => {
  const { city } = pageContext;
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={`${city.name} | Travel Lingual`}
      description={city.description}
      pathname={location.pathname}
    />
  );
};
